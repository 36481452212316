import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { Formik, Form } from 'formik'
import '/src/components/Base/SlickSlider/slick.scss'
import '/src/components/Base/SlickSlider/slick-theme.scss'
import './styles.scss'

const ContentSlider = loadable(() => import('react-slick'))
const StepChildren = loadable(() => import('./StepChildren'))
const StepHome = loadable(() => import('./StepHome'))
const StepLocation = loadable(() => import('./StepLocation'))
const StepOutgoings = loadable(() => import('./StepOutgoings'))
const StepMortgage = loadable(() => import('./StepMortgage'))
const StepRent = loadable(() => import('./StepRent'))
const StepResults = loadable(() => import('./StepResults'))
const StepSavings = loadable(() => import('./StepSavings'))
const StepSex = loadable(() => import('./StepSex'))

const FamilyQuiz = ({ utm }) => {
    const contentSlider = useRef(null)

    const contentSliderSettings = {
        dots: false,
        infinite: false,
        speed: 700,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: false,
        adaptiveHeight: true
    }

    // Generate totals
    const total = useRef(null)

    const generateTotal = (values) => {
        const expensesTotal = values.mortgage + values.rent + Number(values.outgoings) - values.savings

        if (expensesTotal > 0) {
            total.current.innerHTML = `
                <h3 class="c-family-quiz__card-title">Total cover required: £${expensesTotal.toLocaleString()}</h3>
                <p class="c-family-quiz__card-description">Your loved ones could benefit from some life insurance</p>
                <div class="c-family-quiz__card-content">
                    <p>Based on the calculations, it looks like you need some life insurance to cover some of the costs you've stipulated and to protect your family.</p>
                    <p>Fortunately, that's what we're good at! Now you know how much cover you need we can provide you with quotes for an adequate level of protection.</p>
                </div>
            `
        } else {
            total.current.innerHTML = `
                <h3 class="c-family-quiz__card-title">No cover required</h3>
                <p class="c-family-quiz__card-description">Well, it looks like you might not need life insurance!</p>
                <div class="c-family-quiz__card-content">
                    <p>Lucky for some, eh? You have enough in savings and other protection policies that you don't require an additional policy to cover any of the essential costs you've stipulated.</p>
                    <p>However, if you still want to proceed with getting an additional policy, we can certainly help! Use our Quote Tool to find quotes for you, and we'll be in touch to get another policy arranged.</p>
                </div>
            `
        }
    }

    const next = () => {
        contentSlider.current.slickNext()
    }

    return (
        <div className="c-section__content">
            <div className="row">
                <div className="col-lg-10 offset-lg-1">
                    <Formik
                        initialValues={{
                            sex: '',
                            children: '',
                            home: '',
                            mortgage: 0,
                            rent: 0,
                            location: '',
                            outgoings: 0,
                            savings: 0
                        }}
                        validateOnBlur={true}
                        onSubmit={(values, actions) => {
                            generateTotal(values)
                            next()
                        }}
                    >
                        {({ isSubmitting, values }) => (
                            <Form>
                                <ContentSlider ref={contentSlider} arrows={false} {...contentSliderSettings}>
                                    <StepSex />

                                    <StepChildren />

                                    <StepHome />

                                    {values.home === 'mortgage' ? (
                                        <StepMortgage values={values} />
                                    ) : values.home === 'rent' ? (
                                        <StepRent values={values} />
                                    ) : null}

                                    <StepLocation />

                                    <StepOutgoings />

                                    <StepSavings values={values} />

                                    <StepResults total={total} utm={utm} />
                                </ContentSlider>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

FamilyQuiz.propTypes = {
    /**
     * Optional page UTMs - e.g. `?utm_campaign=campaign_name&utm_source=source_name`
     */
    utm: PropTypes.string
}

FamilyQuiz.defaultProps = {}

export default FamilyQuiz
